import React from "react"
// import { Link } from "gatsby"
import classNames from "classnames"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { CDNBASE } from "../utils/maps"
import { CasesSvgs } from "../components/svgs"
import UsersSay from "../components/usersSay"
import CatNav from "../components/catnav"

import cases from "../json/cases.json"
import st from "./css/cases.module.scss"

class CassesPage extends React.Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      const hash = window.location.hash
      const ele = this.refs[hash.substr(1)]
      if (!ele) return

      window.scrollTo(
        0,
        document.documentElement.scrollTop + ele.getClientRects()[0].top - 55
      )
    }
  }
  render() {
    return (
      <Layout cur="/cases/" stickyHeader>
        <SEO
          title="客户案例"
          keywords="客户评价,酷课评价,使用案例,客户案例,舞房约课,瑜伽约课,早教约课,运动约课,艺术约课,"
          description="正在使用酷课约课系统的客户包括舞蹈培训机构、瑜伽馆、艺术教育机构、早教培训机构、运动培训机构。酷课约课系统3年中积累了大量客户好评。"
        />

        <CasesSvgs />
        <UsersSay />
        <h1 className={st.h1}>哪些行业在使用酷课约课系统？</h1>
        <CatNav cases={cases} />

        {cases.map((v, i) => (
          <section
            key={v.icon}
            id={v.icon}
            ref={v.icon}
            className={st.catCase}
            style={{ backgroundColor: v.bg }}
          >
            <div className={classNames(st.catCaseWrap, "clearfix")}>
              <div className={st.catLogos}>
                <div
                  className={st.catTitle}
                  style={{
                    backgroundImage: `url(${CDNBASE}/home/cases/fonts.png?011)`,
                    backgroundPositionX: i * (100 / (cases.length - 1)) + "%",
                  }}
                ></div>
                {v.logos.map((brand, bi) => (
                  <div
                    key={brand}
                    className={st.logo}
                    style={{
                      backgroundImage: `url(${CDNBASE}/home/cases/logos/${v.icon}/${bi}.png?012)`,
                    }}
                  >
                    <div className={st.brand}>{brand}</div>
                    <div className={st.minicode}>
                      <img
                        src={`${CDNBASE}/home/cases/minicodes/${v.icon}/${bi}.jpeg?012`}
                        alt={brand + "小程序码"}
                      />
                      <div className={st.scanTip}>微信扫码体验</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={st.catShows}>
                {v.shows
                  .slice(1, 3) //todo:slider show
                  .map((sv, si) => (
                    <div className={st.showItem} key={si}>
                      <div className={st.imgWrap}>
                        <img
                          src={`${CDNBASE}/home/cases/${v.icon}/${si + 1}.jpg`}
                          alt={sv.title}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        ))}
      </Layout>
    )
  }
}

export default CassesPage
